import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    extras: [],
    extra: null,
    meta: { currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0 },
    extraPaginationPage: 0,
};
// ----------------------------------------------------------------------
export const getExtras = createAsyncThunk('rentableExtras/getExtras', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', branchId = '', agencyId, isPaging, }) => {
    let data;
    try {
        const queryParams = {
            search: {
                'branch.id': branchId,
                agencyId,
            },
            page: Number(page) + 1,
            perPage: Number(rowsPerPage),
            orderBy,
            sortedBy: order,
            isPaging,
        };
        const url = createDynamicURL('/rentableExtras', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createExtra = createAsyncThunk('rentableExtras/createExtra', async ({ Data, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.post(`/rentableExtras`, Data);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getExtras({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const editExtra = createAsyncThunk('rentableExtras/editExtra', async ({ id, Data, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.put(`/rentableExtras/${id}`, Data);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getExtras({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteExtras = createAsyncThunk('rentableExtras/deleteExtras', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/rentableExtras/many`, { data: [...ids] });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getExtras({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'extra',
    initialState,
    reducers: {
        handleChangePaginationExtraPage: (state, action) => {
            state.extraPaginationPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createExtra.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createExtra.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createExtra.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(editExtra.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(editExtra.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(editExtra.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getExtras.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getExtras.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.extras = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getExtras.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const { handleChangePaginationExtraPage } = slice.actions;
export const reducer = slice.reducer;
export default slice;
